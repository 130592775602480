import { Component, inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DetailConfigService } from '@shared/services/detail-config.service';
import { LayoutConfig } from '@shared/services/layout-config.service';
import { DxpBaseEntityComponent } from '@core/base/base-entity.class';
import { Store } from '@ngxs/store';
import { GeneralSelectors } from '@store/admin/general/general.selectors';
import { Farm } from '@core/models/farm.model';
import { IEntityBase } from '@core/interfaces/entity-base';
import { distinctUntilChanged, filter, pairwise, takeUntil } from 'rxjs/operators';
import { AppRoutes } from '@core/enums/routes.enum';

@Component({
  template: '',
})
export class DxpBaseDetailComponent<EntityModel extends IEntityBase>
  extends DxpBaseEntityComponent<EntityModel>
  implements OnInit
{
  public activeFarm$: Observable<Farm>;
  // Injectable dependencies:
  protected detailConfigService = inject(DetailConfigService);
  public store = inject(Store);

  constructor(protected route: ActivatedRoute) {
    super(route);

    this.activeFarm$ = this.store.select(GeneralSelectors.getActiveFarm);
  }

  public ngOnInit(): void {
    this.entityConfig = {
      ...this.entityConfig,
      id: this.route.snapshot.paramMap.get('id'),
    };

    if (this.entityConfig.id) {
      this.getConfig();
    } else {
      console.error('No identifier could be detected!');
    }

    this.listenForFarmChange();
  }

  protected generateDefaultConfig(entity: EntityModel): Observable<LayoutConfig> {
    return this.detailConfigService.generateDefaultConfig(entity, this.entityConfig, this.pageConfig);
  }

  protected handleFarmUpdate(farm?: Farm): void {
    const backUrl = this.layoutConfig && this.layoutConfig.backUrl;

    if (this.entityConfig.displayName) {
      this.router.navigate([`/${AppRoutes.admin}/${this.entityConfig.displayName}`]);
    } else if (this.entityConfig.name) {
      this.router.navigate([`/${AppRoutes.admin}/${this.entityConfig.name}`]);
    } else if (backUrl) {
      Array.isArray(backUrl) ? this.router.navigate(backUrl) : this.router.navigateByUrl(backUrl);
    }
  }

  private listenForFarmChange(): void {
    this.activeFarm$
      .pipe(
        distinctUntilChanged(),
        pairwise(),
        filter(([oldValue, newValue]) => {
          return oldValue?.id !== newValue?.id;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(([, farm]) => {
        return this.handleFarmUpdate(farm);
      });
  }
}
