import { Injectable } from '@angular/core';
import { ActionButton, ButtonType, LayoutConfig, LayoutConfigService } from '@shared/services/layout-config.service';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppRoutes } from '@core/enums/routes.enum';
import { PreviousRouteService } from '@core/services/previous-route.service';
import { DisplayEntity, Entity } from '@core/enums/entity.enum';
import { TranslateService } from '@ngx-translate/core';
import { CrudAction } from '@core/enums/crud-action.enum';
import { Router } from '@angular/router';
import { EntityConfig, getPermissionGroup, getUserFriendlyName } from '@core/models/entity-config.model';
import { DestroyBase } from '@core/base/destroy.class';
import { IEntityBase } from '@core/interfaces/entity-base';

@Injectable({
  providedIn: 'root',
})
export class DetailConfigService<EntityModel extends IEntityBase> extends DestroyBase {
  constructor(
    private layoutConfigService: LayoutConfigService,
    private previousRouteService: PreviousRouteService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    super();
  }

  public generateEditUrl(entityConfig: EntityConfig): string[] {
    return ['/', AppRoutes.admin, getUserFriendlyName(entityConfig), AppRoutes.edit, entityConfig.id];
  }

  public generateEditButton(entityConfig: EntityConfig): ActionButton {
    return {
      type: ButtonType.EDIT,
      label: this.generateButtonLabel(CrudAction.edit, entityConfig.name),
      styleClass: 'secondary',
      icon: 'fa fa-pencil-alt',
      permissions: [`dxp.${getPermissionGroup(entityConfig)}.manage`],
      callback: () => {
        this.router.navigate(this.generateEditUrl(entityConfig));
      },
    };
  }

  public generateSubtitle(additionalParts: (string | Observable<string>)[]): string | Observable<string> {
    const subtitleKeys: (string | Observable<string>)[] = [this.translateService.stream('dxp.subtitle.details')];

    return this.layoutConfigService.generateSubtitle([...subtitleKeys, ...additionalParts]);
  }

  public generateDefaultConfig(
    item: EntityModel,
    entityConfig: EntityConfig,
    pageConfig: Partial<LayoutConfig>,
  ): Observable<LayoutConfig> {
    const additionSubtitleParts = [];

    if (item?.hasOwnProperty('id')) {
      additionSubtitleParts.push(`#${item.id}`);
    }

    if (item?.hasOwnProperty('status')) {
      if (item?.status?.hasOwnProperty('value')) {
        additionSubtitleParts.push(item.status.value);
      } else {
        additionSubtitleParts.push(item.status);
      }
    }

    return this.layoutConfigService.generateDefaultConfig(entityConfig).pipe(
      map((defaultConfig: LayoutConfig) => {
        return {
          ...defaultConfig,
          subtitle: this.generateSubtitle(additionSubtitleParts),
          backUrl: this.previousRouteService.getDefaultBackUrl(AppRoutes.detail, entityConfig),
          actionButtons: [this.generateEditButton(entityConfig)],
          ...pageConfig,
        } as LayoutConfig;
      }),
      takeUntil(this.destroy$),
    );
  }

  public generateButtonLabel(crudAction: CrudAction, entityName: Entity | DisplayEntity): Observable<string> {
    return combineLatest([
      this.translateService.stream(`dxp.button.${crudAction}`),
      this.translateService.stream(`dxp.${entityName}.entity_name`),
    ]).pipe(
      map(([action, entity]) => {
        return `${action} ${entity}`;
      }),
      takeUntil(this.destroy$),
    );
  }
}
